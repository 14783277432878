import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Background from "../assets/img/caseHalf.jpg";
import Background2 from "../assets/img/services/services.jpg";
import CaseImg1 from "../assets/img/case/case1.png";
import CaseImg2 from "../assets/img/case/case2.png";
import CaseImg3 from "../assets/img/case/case3.jpg";
import CaseImg4 from "../assets/img/case/case4.jpg";
import CaseImg5 from "../assets/img/case/case5.png";
import CaseImg6 from "../assets/img/case/case6.png";
import CaseImg7 from "../assets/img/case/case7.png";
import CaseImg8 from "../assets/img/case/case8.png";
import CaseImg9 from "../assets/img/case/case9.png";
import CaseImg10 from "../assets/img/case/case10.png";
import CaseImg11 from "../assets/img/case/case11.png";
import CaseImg12 from "../assets/img/case/case12.png";

function CasePage() {
    const BK1 = { backgroundImage: `url(${Background})` };
    const BK2 = { backgroundImage: `url(${Background2})` };
    const caseImg1 = { backgroundImage: `url(${CaseImg1})` };
    const caseImg2 = { backgroundImage: `url(${CaseImg2})` };
    const caseImg3 = { backgroundImage: `url(${CaseImg3})` };
    const caseImg4 = { backgroundImage: `url(${CaseImg4})` };
    const caseImg5 = { backgroundImage: `url(${CaseImg5})` };
    const caseImg6 = { backgroundImage: `url(${CaseImg6})` };
    const caseImg7 = { backgroundImage: `url(${CaseImg7})` };
    const caseImg8 = { backgroundImage: `url(${CaseImg8})` };
    const caseImg9 = { backgroundImage: `url(${CaseImg9})` };
    const caseImg10 = { backgroundImage: `url(${CaseImg10})` };
    const caseImg11 = { backgroundImage: `url(${CaseImg11})` };
    const caseImg12 = { backgroundImage: `url(${CaseImg12})` };

    const [casesPerPage, setCasesPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(0);

    const cases = [
        {
            title: "社區管理系統",
            text: ["提供住戶資料、繳費管理", "公告通知及包裏管理，提升社區管理效率"],
            imgStyle: caseImg12,
        },
        {
            title: "線上教學電子白板",
            text: ["結合多項元素讓教師自由創作的電子學學白板", "以利於線上教學"],
            imgStyle: caseImg11,
        },
        {
            title: "桃園機場多媒體檔案管理系統",
            text: ["集中存取與管理機場影音、文件等多媒體資料", "提升檔案運用效率"],
            imgStyle: caseImg10,
        },
        {
            title: "電子票卡系統",
            text: ["整合票務及點數功能", "提供便捷快速的活動入場服務"],
            imgStyle: caseImg9,
        },
        {
            title: "高雄榮總語音轉字幕",
            text: ["實現醫療會議語音自動轉文字", "提升記錄精確性與效率"],
            imgStyle: caseImg8,
        },
        {
            title: "活動自助報到系統",
            text: ["提供快速、高效的參加者報到流程，結合身份驗證", "減少人力負擔並提升活動體驗"],
            imgStyle: caseImg7,
        },
        {
            title: "清華雲MOOCS平台",
            text: ["國立清華大學MOOCs平台"],
            imgStyle: caseImg1,
            url: "https://mooc.nthu.edu.tw",
        },
        {
            title: "HEIMDALLR遠距監考系統",
            text: ["結合真人線上遠端監控之遠距考試系統"],
            imgStyle: caseImg2,
        },
        {
            title: "華南HNWU公會平台系統",
            text: ["華南HNWU公會的高效帳務管理與訊息交流平台"],
            imgStyle: caseImg3,
        },
        {
            title: "兆基入居者審查系統",
            text: ["快速、準確、安全驗證入居者是否擁有良好記錄"],
            imgStyle: caseImg4,
        },
        {
            title: "影片字幕",
            text: ["運用人工智慧迅速產生多國語言字幕，與方便替換字幕的系統"],
            imgStyle: caseImg5,
        },
        {
            title: "設計師客戶儲值管理系統",
            text: ["髮型設計師的專屬介紹與顧客儲值管理"],
            imgStyle: caseImg6,
        },
    ];
    function CaseItem({ title, text, imgStyle, url }) {
        return (
            <div className="item">
                <div className="caseImg">
                    <div className="img" style={imgStyle}></div>
                </div>
                <div className="caseTitle">{title}</div>
                <div className="caseText">
                    {text.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>

                {url && (
                    <div className="caseUrl">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            了解更多內容 {" >>"}
                        </a>
                    </div>
                )}
            </div>
        );
    }
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setCasesPerPage(cases.length);
            } else {
                setCasesPerPage(6);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const totalPages = Math.ceil(cases.length / casesPerPage);
    const displayedCases = cases.slice(currentPage * casesPerPage, (currentPage + 1) * casesPerPage);

    function nextPage() {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    }
    function prevPage() {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    }
    return (
        <div id="casePage" className="casePage servicesPage pageSettings">
            <Navbar />

            <div className="ipadBK ipad" style={BK2}>
                <div className="blackBlock"></div>
                <div className="textBox">
                    <div className="text">案例介紹</div>
                    <div className="text2">專為您打造專屬系統</div>
                </div>
            </div>
            <section className="mainPage">
                <div className="content">
                    <div className="contentData">
                        <div className="BKImg pc" style={BK1}>
                            <div className="blackBlock"></div>
                            <div className="textBox">
                                <div className="title mb-3">案例介紹</div>
                                <div className="text">專為您打造專屬系統</div>
                                <div className="text">提供量身訂做的解決方案</div>
                            </div>
                        </div>
                        <div className="dataInfo">
                            <div className="btnBox">
                                <button className="btn prevBtn" onClick={prevPage} disabled={currentPage === 0}>
                                    <i className="bi bi-play-fill"></i>
                                </button>
                            </div>
                            <div className="case">
                                {displayedCases.map((caseItem, index) => (
                                    <CaseItem key={index} {...caseItem} />
                                ))}
                            </div>
                            <div className="btnBox">
                                <button
                                    className="btn nextBtn"
                                    onClick={nextPage}
                                    disabled={currentPage === totalPages - 1}
                                >
                                    <i className="bi bi-play-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default CasePage;
